var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-5",attrs:{"dense":"","justify":"start"}},[_c('v-col',{attrs:{"cols":"6","sm":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$_strings.invoice.DATE))]),_c('v-menu',{ref:"menuFromDate",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"From Date","value":_vm.dayjs(_vm.filter.dateFrom).format('DD-MM-YYYY'),"prepend-inner-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFromDate),callback:function ($$v) {_vm.menuFromDate=$$v},expression:"menuFromDate"}},[_c('v-date-picker',{attrs:{"max":_vm.filter.dateTo,"no-title":"","locale":"id"},model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}})],1)],1),_c('v-col',{staticClass:"mt-8 d-none d-sm-block",attrs:{"cols":"auto"}},[_vm._v("-")]),_c('v-col',{attrs:{"cols":"6","sm":"auto"}},[_c('v-menu',{ref:"menuToDate",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"caption mt-6",attrs:{"dense":"","hide-details":"","outlined":"","value":_vm.dayjs(_vm.filter.dateTo).format('DD-MM-YYYY'),"placeholder":"To Date","prepend-inner-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuToDate),callback:function ($$v) {_vm.menuToDate=$$v},expression:"menuToDate"}},[_c('v-date-picker',{attrs:{"no-title":"","min":_vm.filter.dateFrom,"locale":"id"},model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}})],1)],1),_c('v-col',{staticClass:"ml-sm-4",attrs:{"cols":"auto"}},[_c('span',[_vm._v("Filter")]),_c('v-menu',{attrs:{"bottom":"","close-on-content-click":_vm.closeOnContentClick,"width":"300","offset-y":"","z-index":"1","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"caption",attrs:{"hide-details":"","height":"10","prepend-inner-icon":"mdi-filter","placeholder":_vm.$_strings.invoice.FILTER,"outlined":"","dense":"","readonly":""},on:{"click":function($event){_vm.closeOnContentClick = false}}},'v-text-field',attrs,false),on))]}}])},[_c('v-list',{staticClass:"overflow-auto",attrs:{"width":"300","min-height":"150","max-height":"400"}},[_c('section',{staticClass:"ml-4"},[_c('span',{staticClass:"caption font-weight-bold"},[_vm._v(_vm._s(_vm.$_strings.invoice.SHIPPER))]),_c('common-auto-complete-items',{staticClass:"caption mb-4 mr-4",attrs:{"type":"masterShipper","searchName":"companyName","item-value":"id","item-text":"companyName","flat":"","dense":"","hide-details":"","clearable":"","menu-props":{ bottom: true, offsetY: true },"placeholder":_vm.$_strings.invoice.FILTER_SHIPPER,"filter":_vm.filterCompanyPagination,"items":_vm.companyItems},on:{"updateItems":_vm.updateListCompany},model:{value:(_vm.filter.billedToCompanyId),callback:function ($$v) {_vm.$set(_vm.filter, "billedToCompanyId", $$v)},expression:"filter.billedToCompanyId"}})],1),_c('section',{staticClass:"pl-2 pr-2"},[_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","color":"red","block":""},on:{"click":function($event){_vm.closeOnContentClick = true}}},[_vm._v(" "+_vm._s(_vm.$_strings.common.CLOSE)+" ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }