<template>
  <v-row
    dense
    justify="start"
    class="pa-5"
  >
    <v-col
      cols="6"
      sm="auto"
    >
      <span>{{ $_strings.invoice.DATE }}</span>
      <v-menu
        ref="menuFromDate"
        v-model="menuFromDate"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            hide-details
            outlined
            class="caption"
            placeholder="From Date"
            :value="dayjs(filter.dateFrom).format('DD-MM-YYYY')"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          :max="filter.dateTo"
          v-model="filter.dateFrom"
          no-title
          locale="id"
        >
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="auto" class="mt-8 d-none d-sm-block">-</v-col>
    <v-col cols="6" sm="auto">
      <v-menu
        ref="menuToDate"
        v-model="menuToDate"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            hide-details
            class="caption mt-6"
            outlined
            :value="dayjs(filter.dateTo).format('DD-MM-YYYY')"
            placeholder="To Date"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          no-title
          :min="filter.dateFrom"
          v-model="filter.dateTo"
          locale="id"
        >
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="auto" class="ml-sm-4">
      <span>Filter</span>
      <v-menu
        bottom
        :close-on-content-click="closeOnContentClick"
        width="300"
        offset-y
        z-index="1"
        allow-overflow
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            hide-details
            height="10"
            class="caption"
            prepend-inner-icon="mdi-filter"
            :placeholder="$_strings.invoice.FILTER"
            outlined
            dense
            readonly
            v-bind="attrs"
            @click="closeOnContentClick = false"
            v-on="on"
          ></v-text-field>
        </template>
        <v-list
          width="300"
          min-height="150"
          max-height="400"
          class="overflow-auto"
        >
          <section class="ml-4">
            <span class="caption font-weight-bold">{{ $_strings.invoice.SHIPPER }}</span>
            <common-auto-complete-items
              type="masterShipper"
              searchName="companyName"
              item-value="id"
              item-text="companyName"
              v-model="filter.billedToCompanyId"
              flat
              dense
              hide-details
              class="caption mb-4 mr-4"
              clearable
              :menu-props="{ bottom: true, offsetY: true }"
              :placeholder="$_strings.invoice.FILTER_SHIPPER"
              :filter="filterCompanyPagination"
              :items="companyItems"
              @updateItems="updateListCompany"
            />
          </section>
          <section class="pl-2 pr-2">
            <v-btn
              depressed
              class="white--text"
              color="red"
              block
              @click="closeOnContentClick = true"
            >
              {{$_strings.common.CLOSE}}
            </v-btn>
          </section>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    const { companyName, billedToCompanyId } = this.$route.query;
    if (companyName) {
      this.companyItems.push({
        companyName,
        id: +billedToCompanyId,
      });
    }
  },
  data() {
    return {
      companyItems: [],
      filterCompanyPagination: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
      menuFromDate: false,
      menuToDate: false,
      closeOnContentClick: false,
    };
  },
  watch: {
    filter: {
      handler() {
        this.handleFilter();
      },
      deep: true,
    },
  },
  methods: {
    dayjs,
    handleFilter() {
      let newCompanyName;
      if (this.filter.billedToCompanyId) {
        newCompanyName = this.companyItems.find((c) => c.id === this.filter.billedToCompanyId).companyName;
      }
      const newFilter = {
        ...this.filter,
        companyName: newCompanyName,
      };
      this.$emit('setFilter', newFilter);
    },
    updateListCompany(items) {
      this.companyItems = [...this.companyItems, ...items];
    },
  },
};
</script>
