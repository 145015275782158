<template>
  <v-data-table
    calculate-widths
    item-key="invoiceNo"
    :loading="isLoading"
    :headers="headers"
    :items="items"
    :server-items-length="totalData"
    :options.sync="pagination"
    @click:row="rowClicked"
    :footer-props="{
      showCurrentPage: true,
      showFirstLastPage: true,
      'items-per-page-options': $_item_per_page
    }"
  >
    <template v-slot:[`item.invoiceGroup`]={item}>
      <p class="ma-0">{{ renamePeriode(item.invoiceGroup) }}</p>
      <span class="caption">
        {{ dateFormat(item.periodStart) }} s/d {{ dateFormat(item.periodEnd) }}
      </span>
    </template>
    <template v-slot:[`item.totalAmount`]={item}>
      <span>{{ formatMoney(item.totalAmount) }}</span>
      <br/>
      <span
        v-if="item.invoiceType === 'Pengiriman'"
        class="size-12"
      >
        {{ item.totalShipment }} Pesanan
      </span>
      <span class="size-12" v-else>
        {{ item.totalShipment }} Biaya
      </span>
    </template>
  </v-data-table>
</template>
<script>
import {
  dateFormat,
  handleSortBy,
  handlerPagination,
  renamePeriode,
  defaultPagination,
  formatMoney,
  skipEmptyStringObject,
} from '@/helper/commonHelpers';

export default {
  props: {
    key_list: {
      type: Number,
      default: 0,
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.invoice.SHIPPER_NAME,
          value: 'billedToCompanyName',
          class: 'white--text primary text-capitalize',
          width: '180px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.ORDER_PERIODE,
          value: 'invoiceGroup',
          class: 'white--text primary text-capitalize',
          width: '250px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'lokasiAsal',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'lokasiTujuan',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.TOTAL_BILL,
          value: 'totalAmount',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.INVOICE_TYPE,
          value: 'invoiceType',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.CREATED_BY,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
          width: '180px',
          cellClass: 'clickable',
        },
      ],
      items: [],
      totalData: 0,
      pagination: defaultPagination(),
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    renamePeriode,
    formatMoney,
    rowClicked(item) {
      this.$router.push({
        name: 'invoice-customer-fixed-price-cancel-detail',
        params: {
          invoiceId: item.id,
        },
      });
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const { search } = this.$route.query;
        const {
          page, itemsPerPage: size, sortBy, sortDesc,
        } = this.pagination;
        const _filter = skipEmptyStringObject({
          ...this.filter,
          page: page - 1,
          size,
          sort: handleSortBy({ sortBy, sortDesc }),
        });
        if (search) _filter.invoiceNo = `qlike(${search})`;
        const result = await this.$_services.invoiceFixedPriceCustomerCancel.fetchList(_filter);
        this.items = result.data.contents;
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
